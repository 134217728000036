import {html, LitElement} from "lit";

// Note: we are not using decorators because ESBuild does not support them.
export class BreadCrumbs extends LitElement {

    render() {
        return html`
            <style>
                :host {
                    box-sizing: border-box;
                    display: inline-block;
                }

                .breadcrumb {
                    box-sizing: border-box;
                    height: var(--bread-crumb-background-height, 36px);
                    position: relative;
                    /* The tool bar usually already has padding */
                    /* padding-left: var(--lumo-space-s, 8px); */
                    padding-right: var(--lumo-space-s, 8px);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                ::slotted(*) {
                    display: inline;
                    font-size: var(--lumo-font-size-s);
                }

                ::slotted(:not(:first-child))::before {
                    margin-left: var(--lumo-space-s, 8px);
                    margin-right: var(--lumo-space-s, 8px);
                    content: ">";
                }
                
                
            </style>

            <div class="breadcrumb">
                <slot></slot>
            </div>
        `;
    }
}
customElements.define('bread-crumbs', BreadCrumbs);


// Note: we are not using decorators because ESBuild does not support them.
export class BreadCrumbsElement extends LitElement {

    static get properties() {
        return {
            href: {type: String, reflect: true}
        };
    }

    // We need to declare the properties we use to satisfy the TypeScript compiler.
    // Otherwise, it would complain in all places where we use this property because the compiler does not know
    // that we actually defined it in the "properties" method.
    // A declare statement does actually not produce any javascript code. It is really only there to satisfy the compiler.
    declare href: string;

    render() {
        return html`
            <style>
                a,
                a:visited,
                a:where(:any-link),
                a:hover {
                    color: var(--ci-link-color) !important;
                    text-decoration: var(--ci-link-deoration, underline) !important;
                }
            </style>
            <span>
        ${this.href === "#" ? html`
            <slot></slot>` : html`<a href="${this.href}">
            <slot></slot>
        </a>`}
      </span>
        `;
    }
}


customElements.define('bread-crumbs-element', BreadCrumbsElement);
